<template>
    <div class="header-banner m-0 bg-dark text-white">
        <!-- 
            When removing this banner, you'll need to make the following edits:

            1. Banner.vue: Set data.show: false
            2. RibbonBanner.vue: apply .bannerWrapper {margin-top: 5rem}
            3. Navigation.vue: remove style="" attribute from navbar

            Testing whitespace change?
        -->

        <!-- Next Gen Math is looking for {{listings[0].role}}! &nbsp;
        <a href="/careers" target="_blank" rel="noopener" class="nav-link pl-0 underline">
            <u>Click here to apply.</u>
        </a> -->

        <template v-if="mobileView">
            <span class="mr-2">Get the free Countdown to CAASPP program. <a 
                href="https://46478845.hs-sites.com/countdown-to-caaspp-to-accelerate-math-success-free-program" 
                target="_blank" 
                rel="noopener noreferrer"
                class="text-white text-underline">Click here.</a>
            </span>
        </template>
        <template v-else>
            <span class="mr-2">The free Countdown to CAASPP Pilot Program is now open for new schools! <a 
                href="https://46478845.hs-sites.com/countdown-to-caaspp-to-accelerate-math-success-free-program" 
                target="_blank" 
                rel="noopener noreferrer"
                class="text-white text-underline">Click here to learn more and secure your spot.</a>
            </span>
        </template>
    </div>
</template>

<script>
    export default {
        data: () => ({
            listings: [
                {
                    role: 'Per Diem Professional Development Providers',
                    linkToDescription: '',
                    linkToApplication: 'https://docs.google.com/forms/d/e/1FAIpQLSe0lBxkDJA9KpPt8W1NjuqJLDnBfssGNNFRiHPsPuXVaBNtcw/viewform?usp=sf_link',
                },
                {
                    role: 'Instructional Video Creator',
                    linkToDescription: '',
            linkToApplication: 'https://www.indeed.com/job/mathematics-teacher-6aa5239a15ff9356?_ga=2.80496983.1966785984.1672788846-400955871.1672788846',
                },
            ]
        }),
        computed: {
            mobileView() {
                return window.innerWidth <= 990;
            },
        },
    }
// const headerNotification = document.getElementById('header-notification');
// const dontShowAgain = document.getElementById('hide-job-notification');
// const isEnabled = headerNotification ? headerNotification.getAttribute('data-enabled') : false;

// if (headerNotification) {
//     dontShowAgain.addEventListener('click', function() {
//         localStorage.setItem('hide_job_notification', true);
//         headerNotification.style.display = 'none';
//     });

//     if (!localStorage.getItem('hide_job_notification') && isEnabled) {
//         headerNotification.style.display = 'block';
//     } else {
//         headerNotification.style.display = 'none';
//     }
// }
</script>

<style lang="scss" scoped>
    .header-banner {
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        width: 100%;
        height: 36px;
        position: fixed;
        border-bottom: 1px solid #000;
        z-index: 20;
        a.btn {
            color: #000 !important;
            font-weight: 600;
        }

        a.text-underline {
            text-decoration: underline;
        }
    }
</style>
